import React from "react"
import { Link, withPrefix } from "gatsby"
export default () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-12 text-center">
          <h2>Got Questions?</h2>
          <p>
            Check out for our{" "}
            <Link to={withPrefix("/faq")} className="link">
              FAQ
            </Link>{" "}
            page for answer commonly asked questions.
            <br />
            Feel Free to{" "}
            <Link to={withPrefix("/support")} className="link">
              contact us
            </Link>{" "}
            if you want to know more!
          </p>
        </div>
      </div>
    </div>
  )
}
