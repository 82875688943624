import React from "react"
import { Link, withPrefix } from "gatsby"

export default class HowItWorks extends React.Component {
  constructor(props) {
    super(props)
  }

  static defaultProps = {
    btn_color_class: "btn-pink"        
  }

  render() {
    const { btn_color_class } = this.props
    

    return (
      <>
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h2>How It Works</h2>
              <div className="row">
                <div className="col-6 col-md-3 p-1 p-md-3">
                  <img
                    src={withPrefix("img/ala-carte/how-it-works-new-1.png")}
                    className="img-fluid"
                  />
                </div>
                <div className="col-6 col-md-3 p-1 p-md-3">
                  <img
                    src={withPrefix("img/ala-carte/how-it-works-new-2.png")}
                    className="img-fluid"
                  />
                </div>
                <div className="col-6 col-md-3 p-1 p-md-3">
                  <img
                    src={withPrefix("img/ala-carte/how-it-works-new-3.png")}
                    className="img-fluid"
                  />
                </div>
                <div className="col-6 col-md-3 p-1 p-md-3">
                  <img
                    src={withPrefix("img/ala-carte/how-it-works-new-4.png")}
                    className="img-fluid"
                  />
                </div>
              </div>
              <Link className={`btn ${ btn_color_class } mt-4`} to={withPrefix("/how-it-works")}>
                Learn More About The Process
              </Link>
            </div>
          </div>
        </div>
      </>
    )
  }
}
