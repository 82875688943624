import React from "react"
import { Link, withPrefix } from "gatsby"
import Layout from "../../components/layouts/layout"
import HeadMeta from "../../components/layouts/headmeta"
import ProgressBar from "../../components/layouts/progressbar"
import Faq from "../../components/faq"
import { changeProgressBarOnScroll } from "../../components/utils/index"
import HowItWorks from "../../components/how-it-works"

export default class DNAExplorerOncoPage extends React.Component {
  componentDidMount() {
    window.addEventListener("scroll", function() {
      changeProgressBarOnScroll()
    })
    window.analytics.page("Sales", "DNA Explorer Onco")
  }

  render() {
    return (
      <Layout>
        <div className="product-page" id="dna-explorer">
          <ProgressBar />
          <HeadMeta metadata={pageData.metadata} />
          <section
            className="hero hero-landing bg-gray lp"
            id="dna-explorer-onco"
            style={{
              maxHeight: "90vh",
              backgroundPosition: "center",
            }}
          >
              <div className="container">
                <div className="row d-flex">
                  <div className="col-lg-6 offset-lg-6 text-center text-md-right order-2 order-lg-1 p-4 content">
                    <img
                      className="img-fluid mb-5"
                      src={withPrefix("img/logo/DNA-Explorer-Onco.png")}
                      style={{ maxWidth: "200px" }}
                      alt="DNA Explorer Carrier"
                    />
                    <h1 className='h2'>Discover Your Genetic<br/> Risks for Cancer</h1>
                    <p className='h5 font-weight-normal'>
                      Preventive care starts from <br/> understanding your genes
                    </p>
                    <a
                      className="btn btn-explorer-green mt-3"
                      href="https://app.advanxhealth.com/products/overview?product=48"
                    >
                      Get It Now
                    </a>
                  </div>
                </div>
            </div>
          </section>
          <section className='bg-white '>
            <div className='container text-center'>
              <h3>Brought to you in collaboration with</h3>
              <img
                className='mt-5'
                src="https://www.mgrc.com.my/wp-content/themes/MGRC/img/mgrc-logo.png"
                alt="MGRC"
              />
            </div>
          </section>
          <section id="info" className="info py-5" style={{backgroundColor:'#F7FFF5'}}>
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-6">
                  <img
                    src="/img/1-in-4.png"
                    className="img-fluid my-3"
                    alt="Product box"
                  />
                </div>
                <div className="col-md-6 px-md-5">
                  <p className="lead mt-3">
                    <span className='h5 '>1 in every 4 Malaysians</span> are affected by cancer. In fact, breast cancer and lung cancer are also among the 
                    top causes of death in the country as of 2017.  
                    <br />
                    <br />
                    While lifestyle and dietary habits are among some of the main causes of cancer, your genetic predisposition may 
                    also put you at a higher risk for certain cancer types.
                    <br />
                    <br/>
                    Get screened for cancer-associated genetic markers today!
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section id="features" className="features bg-white">
            <div className="px-md-5 text-center">
              <header>
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <h2 className="text-center mb-4">
                      What's Included In{" "}
                      <img
                        className="img-fluid"
                        src={withPrefix("img/logo/DNA-Explorer-Onco-Oneline.png")}
                        style={{ maxWidth: "300px" }}
                        alt="DNA Explorer Onco"
                      />
                    </h2>
                  </div>
                  <div className="col-md-6 offset-md-3">
                    <p className="text-center">
                      Take the first step towards total health with a DNA screening for genetic markers associated with 32 cancer types
                    </p>
                  </div>
                </div>
               </header>
              <div className="row align-items-center my-4 px-md-5">
                <div className="col-md-6 text-left">
                  <div className="row">
                    <div className="col-md-6">
                      <ul>
                        <li>Basal Cell Carcinoma</li>
                        <li>Bladder Cancer</li>
                        <li>Breast Cancer</li>
                        <li>Cervical Cancer</li>
                        <li>Colorectal Cancer</li>
                        <li>Endometrial Cancer</li>
                        <li>Esophageal Cancer</li>
                        <li>Esophageal Squamos Cell Carcinoma</li>
                        <li>Gallbladder Cancer</li>
                        <li>Glioma Development</li>
                        <li>Hepatocellular Carcinoma</li>
                        <li>Kidney Cancer</li>
                        <li>Larynx Cancer</li>
                        <li>Acute Lymphoblastic Leukaemia</li>
                        <li>Chronic Lymphocytic Leukaemia</li>
                        <li>Lung Cancer</li>
                      </ul>
                    </div>
                    <div className="col-md-6">
                      <ul>
                        <li>Lung Adenocarcinoma</li>
                        <li>Follicular Lymphoma</li>
                        <li>Hodgkin Lymphoma</li>
                        <li>Non-Hodgkin Lymphoma</li>
                        <li>Melanoma</li>
                        <li>Meningioma</li>
                        <li>Nasopharyngeal Cancer</li>
                        <li>Oral and Throat Cancers</li>
                        <li>Osteosarcoma</li>
                        <li>Ovarian Cancer</li>
                        <li>Pancreatic Cancer</li>
                        <li>Prostate Cancer</li>
                        <li>Squamous Cell Carcinoma</li>
                        <li>Stomach Cancer</li>
                        <li>Testicular Germ-Cell Tumour</li>
                        <li>Thyroid Cancer</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <img
                    src={withPrefix("/img/illustrations/onco.svg")}
                    className="img-fluid p-5"
                    alt="Onco Reports"
                  />
                </div>
              </div>
            </div>
          </section>
          <section className="how-it-works py-5" style={{backgroundColor:'#F7FFF5'}} >
            <HowItWorks btn_color_class="btn-explorer-green" />
          </section>
          <section className="p-0 p-md-5" style={{backgroundColor: '#69A863'}}>
            <div
              className="container py-5 my-0"
            >
              <div className="row text-white align-items-center">
                <div className="col-md-1 offset-md-2">
                  <span className="text-left">
                    <i
                      className="fa fa-quote-left mb-4"
                      style={{ fontSize: "2.5rem" }}
                    ></i>
                  </span>
                </div>
                <div className="col-md-6">
                  <h3 className="text-white text-center ">
                    Your DNA loads the gun,
                    <br className="" /> your lifestyle pulls the trigger.
                  </h3>
                </div>
                <div className="col-md-1 text-right text-md-left">
                  <span className="">
                    <i
                      className="fa fa-quote-right mb-4"
                      style={{ fontSize: "2.5rem" }}
                    ></i>
                  </span>
                </div>
              </div>
            </div>
          </section>
          <section id="limitation" className="bg-gray">
            <div className='container'>
              <div className='row'>
                <div className='col-md-3 pr-md-5'>
                  <h2 className='text-gray'>Limitations</h2>
                </div>
                <div className='col-md-9 text-justify'>
                  <p className='lead'>
                    Genetic screening can indicate whether an individual has a predisposition, or is at increased likelihood of having an 
                    inherited disease or disorder. However, it cannot indicate if the individual will show symptoms, how severe the symptoms 
                    will be, or whether the disease or disorder will progress over time.
                  </p>
                  <p className='lead'>
                    A negative test result does not mean that an individual is 100% free from possibilities of inheriting the disease or 
                    disorder; likewise, a positive result also does not mean that an individual is 100% confirmed to develop the disorder or disease over time.
                  </p>
                  <p className='lead'>
                    Genetic screening reports do not diagnose any health conditions or provide medical advice. The reports should not be used as 
                    a diagnostic tool; results provided are also limited to existing scientific research and will be updated upon new findings in research.
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section className="mb-0 bg-light" id="what-you-get">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-5 order-2 order-md-1 text-center text-md-center">
                  <img
                    className="img-fluid"
                    src={withPrefix("img/logo/DNA-Explorer-Onco.png")}
                    alt="DNA Explorer Onco"
                  />
                  <h4 className='my-3'>
                    Get screened for the risk of
                    <br /> 32 cancer types based on your <br />
                    DNA predisposition.
                  </h4>
                  <a
                    href="https://app.advanxhealth.com/products/overview?product=48"
                    className="btn btn-explorer-green mt-4 btn-lg"
                  >
                    Get It Now
                  </a>
                </div>
                <div className="col-md-6 order-1 order-md-2">
                  <img
                    className="d-md-block product-gif"
                    src={withPrefix("/img/gifs/demo-onco.gif")}
                    alt="Sample report"
                  />
                </div>
              </div>
            </div>
          </section>
          <section id="faq" className="py-5">
            <Faq />
          </section>
        </div>
      </Layout>
    )
  }
}

export const pageData = {
  metadata: {
    title: "DNA Explorer Onco | Advanx Health",
    description:
      "Predict & discover your risk against cancer.",
    image: "https://www.advanxhealth.com/img/couple.jpg",
    url: "https://www.advanxhealth.com/products/dna-explorer-onco",
  },
  
}
